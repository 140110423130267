(function() {
  var timer = null,
    $jobsContainer = document.querySelector('.jobs-vertical'),
    loader = document.querySelector('.loader-gif-icon'),
    error = document.querySelector('.jobs-loader-error'),
    timeStart;
  const JOBS_PAGEID = 'tech',
    TIMEOUT = 10000;

  function handleJobsContentReady($jobs) {
    var $jobsGroupTitles = $jobs.querySelectorAll('.rbox-jobs-group > h3');

    for (var i = 0; i < $jobsGroupTitles.length; i++) {
      var $title = $jobsGroupTitles[i],
        title = $title.innerText.trim().toLowerCase();

      if (['engineering', 'automation', 'design'].indexOf(title) < 0) {
        $title.parentNode.remove();
      }
    }
  }

  function handleJobsContentAvailable($jobs) {
    window.clearInterval(timer);
    loader.classList.add('hide');
    error.classList.add('hide');

    if (!checkForJobsContentReady($jobs)) {
      timer = window.setInterval(
        checkForJobsContentReady.bind(null, $jobs),
        1000
      );
    }

    $jobsContainer.appendChild($jobs);
    $jobsContainer.appendChild(document.querySelector('.rbox-opening-detail'));
  }

  function checkForJobsContentReady($jobs) {
    return (
      $jobs.className.indexOf('rendered') >= 0 && handleJobsContentReady($jobs)
    );
  }

  function checkForJobsContent() {
    var $jobsList = document.querySelector('.rbox-opening-list.rbox-widget');
    // if more than TIMEOUT has passed add error loading message
    if (timeStart && new Date() - timeStart > TIMEOUT) {
      loader.classList.add('hide');
      error.classList.remove('hide');
    }
    return $jobsList && handleJobsContentAvailable($jobsList);
  }

  timeStart = new Date();
  // if page is /jobs
  if (document.getElementById(JOBS_PAGEID)) {
    !checkForJobsContent() &&
      (timer = window.setInterval(checkForJobsContent, 100));
  }
})();
